




















































































































































































































































import Base from '@/mixins/Base.vue';
import { IClient } from '@/interfaces/client';
import { ISurvey, ISurveysResponse } from '@/interfaces/survey';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({

    data() {
        return {
            surveysParams: {
                active: null,
                source: null,
                title: '',
                sortBy: 'id',
                orderBy: 'asc',
                clients: [] as Array<IClient>,
                page: 1,
            },

            surveys: {
                data: [] as Array<ISurvey>,
                meta: {} as IPagination | any,
            } as ISurveysResponse,
        };
    },
    mounted() {
        this.getSurveys();
    },
    methods: {
        getSurveys(page: number = 1): void {
            this.surveysParams.page = page;

            this.get<ISurveysResponse>('surveys', {
                params: {
                    ...this.surveysParams,
                    clients: this.surveysParams.clients.map((c) => c.id),
                },
            }).then(({ data }) => {
                this.surveys = data;
            });
        },
    },
});

export default component;
